import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { Icon, Menu, Popup, Tab } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetEquipment2Query } from "../equipmentService";

import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";
import PaneMeasurements from "./PaneMeasurements";
import EquipmentMainForm from "./form/EquipmentMainForm";
import EquipmentSecondaryForm from "./form/EquipmentSecondaryForm";

const EquipmentTab = (props) => {
    const { id_eqpmt } = useParams(); //id_eqmt === undefined for add
    const org = useSelector((state) => state.org);
    useSelector((state) => state.i18n.current); //force refresh for lng
    const [tabIndex, setTabIndex] = useState(0);

    const equipment = useGetEquipment2Query({ org: org.current, data: id_eqpmt }, { skip: !org.current || id_eqpmt === undefined });

    if (equipment.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (equipment.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (equipment.isSuccess) {
        let panes = [];

        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="General information" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`General information`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="info" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`General information`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="info" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="info" />
                                    </Icon.Group>
                                    <Trans>General information</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="building" />
                                <Icon corner="top right" name="info" />
                            </Icon.Group>
                            <Trans>General information</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <EquipmentMainForm equipment={equipment.data} />
                </Tab.Pane>
            )
        });
        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="Additional information" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`Additional information`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`Additional information`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                    <Trans>Additional information</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="building" />
                                <Icon corner="top right" name="plus" />
                            </Icon.Group>
                            <Trans>Additional information</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <EquipmentSecondaryForm equipment={equipment.data} />
                </Tab.Pane>
            )
        });

        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="Measurements" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`Measurements`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`Measurements`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="building" />
                                        <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                    <Trans>Measurements</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="building" />
                                <Icon corner="top right" name="plus" />
                            </Icon.Group>
                            <Trans>Measurements</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <>
                    <Tab.Pane>
                        <PaneMeasurements equipment={equipment.data} />
                    </Tab.Pane>
                </>
            )
        });

        if (equipment.data?.machine !== null) {
            panes.push({
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="MachineEvents" {...props}>
                            <Media lessThan="computer">
                                <Popup content={i18n._(t`Machine events`)} trigger={<Icon name="calendar alternate outline" />} />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && <Popup content={i18n._(t`Machine events`)} trigger={<Icon name="calendar alternate outline" />} />}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                        <Trans>Machine events</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon name="calendar alternate outline" />
                                <Trans>Machine events</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <>
                        <Tab.Pane>TODO EVENTS</Tab.Pane>
                    </>
                )
            });

            panes.push({
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="MachineKinematic" {...props}>
                            <Media lessThan="computer">
                                <Popup content={i18n._(t`Machine kinematic`)} trigger={<Icon name="gg" />} />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && <Popup content={i18n._(t`Machine kinematic`)} trigger={<Icon name="gg" />} />}
                                {active && (
                                    <>
                                        <Icon name="gg" />
                                        <Trans>Machine kinematic</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon name="gg" />
                                <Trans>Machine kinematic</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <>
                        <Tab.Pane>TODO Kinematics</Tab.Pane>
                    </>
                )
            });
        }

        return (
            <Tab
                panes={panes}
                activeIndex={tabIndex}
                onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                menu={{ attached: true, tabular: true, className: "pwaOverviewTabMenu" }}
            />
        );
    }
    return null;
};

export default React.memo(EquipmentTab);
