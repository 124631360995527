import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";
import { process_auto_unit } from "./utils";

export const equipmentApi = defaultApi.injectEndpoints({
    reducerPath: "apiEquipment",
    endpoints: (build) => ({
        getEquipments: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `equipments?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 120,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Equipments", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                return _.map(response, (equipment) => {
                    return { ...equipment, key: equipment.id, text: equipment.name, value: equipment.id };
                });
            }
        }),
        getEquipment: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `equipments/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "Equipment", id: arg.data }];
            },
            transformResponse: (response) => {
                const rewrite_eqpt = process_auto_unit(response);
                return rewrite_eqpt;
            }
        }),
        getEquipment2: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `equipments/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "Equipment", id: arg.data }];
            }
        }),
        createEquipment: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Tag" },
                        { type: "Equipment" } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        createEquipment2: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Tag" },
                        { type: "Equipment" } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        updateEquipment: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Tag" },
                        { type: "Components" },
                        { type: "Equipment", id: arg.data.id.toString() } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        updateEquipment2: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${data.id}/update2?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Components" },
                        { type: "Equipment", id: arg.data.id.toString() } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        deleteEquipment: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${data}?org=${current_org}${confirm ? "&confirm=yes" : ""}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    return [
                        { type: "Equipments", id: arg.org.id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        getEquipmentMeasurements: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `equipments/${data}/measurements?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "EquipmentMeasurements", id: arg.data }];
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        })
    }),
    overrideExisting: false
});

export const {
    useGetEquipmentsQuery,
    useGetEquipmentQuery,
    useCreateEquipmentMutation,
    useUpdateEquipmentMutation,
    useDeleteEquipmentMutation,
    useGetEquipment2Query,
    useCreateEquipment2Mutation,
    useUpdateEquipment2Mutation,
    useGetEquipmentMeasurementsQuery
} = equipmentApi;
