import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field } from "react-final-form";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { getMaxDecimalPlaces, removeAccents, validateNumber } from "modules/common/utils";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import { formulaTypeOptions, syndataflowtypeOptions } from "modules/equipment/utils";

const MeasurementFields = (props) => {
    const { form, values, isSynthetic, isPulse } = props;
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const unitOptions = useMemo(() => {
        if (units.isSuccess) {
            return _.chain(units.data)
                .map(({ key, text, value }) => ({
                    key,
                    value,
                    text: i18n._(text)
                }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [units]);

    /* Display group of categories based on formula_type for synthetic OR pulse case */
    const getCatOptions = useCallback(
        (formulaType) => {
            if (categories.isSuccess) {
                return _.chain(categories.data)
                    .reduce((res, { key, text, value }) => {
                        if (isPulse && _.includes(["elec", "gas", "water", "calories", "frigories", "pulse"], text)) {
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else if (formulaType === 1 && _.includes(["elec", "gas", "water"], text)) {
                            //heat/refrigaration/hydrolic
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else if (formulaType === 2 && _.includes(["water", "calories", "frigories"], text)) {
                            //heat/refrigaration/hydrolic
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else if (formulaType === 3 && _.includes(["gas", "water", "calories", "frigories"], text)) {
                            //generic multiplication
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else {
                            // All other case
                            if (_.includes(["elec", "gas", "water"], text)) {
                                res.push({
                                    key,
                                    value,
                                    text: i18n._(text)
                                });
                            }
                        }
                        return res;
                    }, [])
                    .orderBy((item) => {
                        return removeAccents(item.text).toLowerCase();
                    }, "asc")
                    .value();
            }
            return [];
        },
        // eslint-disable-next-line
        [categories, isPulse, current_lng]
    );

    /* Display group of measurement types based on category selection AND only datapoint_type 3 (time integral) */
    const getMttypeOptions = useCallback(
        (dataflowspec) => {
            if (measurementtypes.isSuccess) {
                return _.chain(measurementtypes.data)
                    .reduce((res, { key, text, value, dataflowspec_set, datapoint_type }) => {
                        if (datapoint_type === 3 && _.includes(dataflowspec_set, dataflowspec)) {
                            if (_.includes(["p_react_import", "p_react_import+", "p_react_import-"], text)) return res; //remove reactive mt_type for elec
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        }
                        return res;
                    }, [])
                    .orderBy((item) => {
                        return removeAccents(item.text).toLowerCase();
                    }, "asc")
                    .value();
            }
            return [];
        },
        // eslint-disable-next-line
        [measurementtypes, current_lng]
    );

    /* Display standby threshold unit based on selected measurementtype */
    const getStandbyThresholdUnit = useCallback(
        (measurementtype) => {
            if (measurementtypes.isSuccess && units.isSuccess) {
                const mttype = _.find(measurementtypes.data, { id: measurementtype });
                const unit = _.find(units.data, { id: mttype?.unit });
                return unit?.intensive ?? "-";
            }
            return "-";
        },
        [measurementtypes, units]
    );

    const current_mttype = values?.measurement?.measurementtype ?? null;

    const display_standby_threshold = useMemo(() => {
        if (measurementtypes.isSuccess) {
            const mttype = _.find(measurementtypes.data, { id: current_mttype });
            if (_.includes(["p_act_import", "e_act_counter", "water_import", "index_nm3"], mttype?.name)) {
                return true;
            }
        }
        return false;
    }, [measurementtypes, current_mttype]);

    return (
        <Grid verticalAlign="top">
            {isSynthetic && (
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Field
                            name={`syndataflow.formula_type`}
                            label={i18n._(t`Formula type`)}
                            placeholder={i18n._(t`select type of formula`)}
                            //only specific categories for pulse
                            options={formulaTypeOptions}
                            isRequired={true}
                            component={DropDownAdapter}
                            customAction={(data) => {
                                form.change("dataflow.dataflowspec", null);
                                form.change("measurement.measurementtype", null);
                                form.change("dataflow.standby_threshold", null);
                                form.change("measurement.display_unit", -1);
                            }}
                            validate={(value) => {
                                if (!value) return <Trans>Required field</Trans>;
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    {values?.syndataflow?.formula_type === 1 && (
                        <Grid.Column width={8}>
                            <Field
                                name={`syndataflow.syndataflowtype`}
                                label={i18n._(t`syndataflowtype`)}
                                placeholder={i18n._(t`select syndataflowtype`)}
                                options={syndataflowtypeOptions}
                                isRequired={true}
                                component={DropDownAdapter}
                                validate={(value) => (!value ? <Trans>Required field</Trans> : undefined)}
                            />
                        </Grid.Column>
                    )}
                </Grid.Row>
            )}
            {(isSynthetic || isPulse) && (
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Field
                            name={`dataflow.dataflowspec`}
                            label={i18n._(t`output category`)}
                            placeholder={i18n._(t`select category`)}
                            //only specific categories for pulse
                            options={getCatOptions(values?.syndataflow?.formula_type)}
                            isRequired={true}
                            component={DropDownAdapter}
                            customAction={(data) => {
                                form.change("measurement.measurementtype", null);
                                form.change("dataflow.standby_threshold", null);
                                form.change("measurement.display_unit", -1);
                            }}
                            validate={(value) => {
                                if (!value) return <Trans>Required field</Trans>;
                                if (value === 8) return <Trans>you need to change default output category</Trans>;
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Field
                            name={`measurement.measurementtype`}
                            label={i18n._(t`output measurementtype`)}
                            placeholder={i18n._(t`select measurementtype for measure`)}
                            options={getMttypeOptions(values?.dataflow?.dataflowspec)}
                            isRequired={true}
                            component={DropDownAdapter}
                            disabled={!_.isFinite(values?.dataflow?.dataflowspec)}
                            customAction={(data) => {
                                form.change("measurement.display_unit", -1);
                                form.change("dataflow.standby_threshold", null);
                            }}
                            validate={(value) => (!value ? <Trans>Required field</Trans> : undefined)}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            {isSynthetic && (
                <Grid.Row>
                    <Grid.Column width={16}>TODO FORMULA</Grid.Column>
                </Grid.Row>
            )}

            {/* Display dataflow name field only for update */}
            {(values?.measurement?.id || true) && (
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Field
                            name="dataflow.name"
                            placeholder={i18n._(t`Name of measurement`)}
                            label={i18n._(t`name`)}
                            isRequired={true}
                            component={InputAdapter}
                            validate={(value) => (!value ? <Trans>Required field</Trans> : undefined)}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.offset`}
                        placeholder={i18n._(t`enter offset for measure`)}
                        label={i18n._(t`offset`)}
                        component={InputAdapter}
                        helperText={`${i18n._(t`offset helper text`)}`}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        validate={(value) => {
                            const numValidate = validateNumber(value, i18n, false, false);
                            if (numValidate !== undefined) {
                                return numValidate;
                            }
                            const val = parseFloat(value);
                            const max_dec = 2;
                            const max_digit = 12;
                            const max_val = Math.pow(10, max_digit - max_dec);
                            if (_.isFinite(val)) {
                                if (val > max_val) {
                                    return <Trans>The offset value must be less than {max_val}</Trans>;
                                }
                                const decPart = getMaxDecimalPlaces(value);
                                if (decPart > max_dec) {
                                    return <Trans>Make sure there are no more than {max_dec} decimal places</Trans>;
                                }
                                if (parseInt(val).toString().length > max_digit - max_dec) {
                                    return <Trans>Make sure you have only {max_digit - max_dec} digits before the decimal part</Trans>;
                                }
                                return undefined;
                            }
                            return <Trans>invalid offset value</Trans>;
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.factor`}
                        placeholder={i18n._(t`enter factor for measure`)}
                        label={i18n._(t`factor`)}
                        component={InputAdapter}
                        helperText={`${i18n._(t`factor used to convert raw values received by sensor to`)} ${getStandbyThresholdUnit(
                            values?.measurement?.measurementtype
                        )}`}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        validate={(value) => {
                            const numValidate = validateNumber(value, i18n, false, false);
                            if (numValidate !== undefined) {
                                return numValidate;
                            }

                            const val = parseFloat(value);
                            const max_dec = 5;
                            const max_digit = 10;
                            const max_val = Math.pow(10, max_digit - max_dec);
                            if (_.isFinite(val)) {
                                if (val > max_val) {
                                    return <Trans>The factore value must be less than {max_val}</Trans>;
                                }
                                const decPart = getMaxDecimalPlaces(value);
                                if (decPart > max_dec) {
                                    return <Trans>Make sure there are no more than {max_dec} decimal places</Trans>;
                                }
                                if (parseInt(val).toString().length > max_digit - max_dec) {
                                    return <Trans>Make sure you have only {max_digit - max_dec} digits before the decimal part</Trans>;
                                }
                                return undefined;
                            }
                            return <Trans>invalid factor value</Trans>;
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.minGauge`}
                        placeholder={i18n._(t`enter minGauge for measure`)}
                        label={i18n._(t`minGauge`)}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="numeric"
                        isRequired={true}
                        validate={(value) => {
                            return validateNumber(value, i18n, true, false, true);
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.maxGauge`}
                        placeholder={i18n._(t`enter maxGauge for measure`)}
                        label={i18n._(t`maxGauge`)}
                        component={InputAdapter}
                        parse={identityNull}
                        isRequired={true}
                        validate={(value) => {
                            return validateNumber(value, i18n, true, false, true);
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.display_unit`}
                        label={i18n._(t`display_unit`)}
                        placeholder={i18n._(t`select display unit for measure`)}
                        options={unitOptions}
                        disabled={!_.isFinite(values?.measurement?.measurementtype)}
                        component={DropDownAdapter}
                        upward={true}
                        cssOverride={true}
                    />
                </Grid.Column>
                {display_standby_threshold && (
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Field
                            name={`dataflow.standby_threshold`}
                            placeholder={i18n._(t`enter standby threshold here`)}
                            unit={getStandbyThresholdUnit(values?.measurement?.measurementtype)}
                            unitposition={"right"}
                            label={i18n._(t`Standby threshold`)}
                            isRequired={true}
                            component={InputAdapter}
                            parse={identityNull}
                            defaultValue={null}
                            validate={(value) => {
                                return validateNumber(value, i18n, false, false);
                            }}
                            helperText={
                                <Trans>
                                    <p>The standby threshold corresponds to the maximum power consumed by the equipment when it is in standby.</p>
                                    <p>
                                        The energy consumed by the equipment below this threshold is then calculated and displayed in the detail view.
                                    </p>
                                </Trans>
                            }
                            cssOverride={true}
                        />
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};

MeasurementFields.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
};

export default React.memo(MeasurementFields);
