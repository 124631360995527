import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Button, Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetEquipmentMeasurementsQuery } from "../equipmentService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import MeasurementForm from "modules/equipment/components/form/MeasurementForm";
import DetachModal from "modules/equipment/components/modal/DetachModal";
import AddMeasurementModal from "modules/equipment/components/modal/AddMeasurementModal";

const PaneMeasurements = (props) => {
    const org = useSelector((state) => state.org);
    const [openAddMeasure, setOpenAdd] = useState(false);
    const [openDetachMeasure, setOpenDetach] = useState(null);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const equipmentMeasurements = useGetEquipmentMeasurementsQuery(
        { org: org.current, data: props?.equipment?.id },
        { skip: !org.current || props?.equipment?.id === undefined }
    );

    const rows = _.chain(_.range(4))
        .map((item, idx) => {
            return (
                <Grid.Column key={idx} mobile={16} tablet={14} computer={14}>
                    <Header
                        as="h3"
                        attached="top"
                        block
                        textAlign="right"
                        id="pwaEquipmentMeasurementHeader"
                        style={{ display: "flex", flexWrap: "wrap", alignItems: "center", padding: "0.3rem" }}
                    >
                        <div style={{ flex: 1 }} />
                        <div style={{ flex: 2, textAlign: "center" }}>
                            <h4>TODO TECHINFO</h4>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                icon="arrows alternate horizontal"
                                onClick={(e) => {
                                    setOpenDetach(item);
                                }}
                            />
                        </div>
                    </Header>
                    <MeasurementForm measurement={item} equipment={props?.equipment} />
                </Grid.Column>
            );
        })
        .value();

    return (
        <>
            <Segment attached="top">
                <Button onClick={(e) => setOpenAdd(true)}>Add calculated measurement</Button>
                <Button>Add efficiency</Button>
            </Segment>
            <Segment attached>
                <Grid centered>{rows}</Grid>
            </Segment>
            {openDetachMeasure !== null && <DetachModal measurement={openAddMeasure} setOpen={setOpenDetach} />}
            {openAddMeasure && <AddMeasurementModal equipment={props.equipment} setOpen={setOpenAdd} />}
        </>
    );

    if (equipmentMeasurements.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (equipmentMeasurements.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (equipmentMeasurements.isSuccess) {
        return <div>TabMeasurements</div>;
    }
    return null;
};

export default React.memo(PaneMeasurements);
