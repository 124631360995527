import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetEquipmentsQuery } from "../equipmentService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetEquipmenttypesQuery } from "../equipmenttypeService";
import { useGetSensortypesQuery } from "modules/sensor/sensorTypeService";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetMachinetypesQuery } from "modules/machine/machinetypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import EquipmentTab from "./EquipmentTab";

const Equipment = (props) => {
    const org = useSelector((state) => state.org);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipmenttypes = useGetEquipmenttypesQuery({ org: org.current }, { skip: !org.current });
    const sensortypes = useGetSensortypesQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const success_list = [
        sites.isSuccess,
        categories.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        measurementtypes.isSuccess,
        measurements.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess,
        machines.isSuccess,
        machinetypes.isSuccess,
        equipmenttypes.isSuccess,
        sensortypes.isSuccess
    ];

    const fetching_list = [
        sites.isFetching,
        categories.isFetching,
        zones.isFetching,
        usages.isFetching,
        tags.isFetching,
        units.isFetching,
        measurementtypes.isFetching,
        measurements.isFetching,
        equipments.isFetching,
        dataflows.isFetching,
        machines.isFetching,
        machinetypes.isFetching,
        equipmenttypes.isFetching,
        sensortypes.isFetching
    ];

    const error_list = [
        sites.isError,
        categories.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        units.isError,
        measurementtypes.isError,
        measurements.isError,
        equipments.isError,
        dataflows.isError,
        machines.isError,
        machinetypes.isError,
        equipmenttypes.isError,
        sensortypes.isError
    ];

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={"/equipments"} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Edit equipment</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {/* DISPLAY LOADER */}
                {_.some(fetching_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {/* DISPLAY ERROR MESSAGES */}
                {_.some(error_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {_.every(success_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <EquipmentTab />
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(Equipment);
