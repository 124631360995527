import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Field, Form } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import { Button, Grid, Segment, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { main_default_equipment, transferTypeOptions } from "modules/equipment/utils";
import { identity } from "modules/common/utils/form";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import EquipmentMainFields from "modules/equipment/components/form/EquipmentMainFields";

const DetachForm = (props) => {
    const { measurement = null, setOpen } = props;
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });

    const equipmentsOptions = useMemo(() => {
        if (equipments.isSuccess) {
            return _.chain(equipments.data)
                .map(({ key, text, value }) => ({
                    key,
                    value,
                    text: i18n._(text)
                }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [equipments, current_lng]);

    const initialValues = useMemo(() => {
        return {
            measurement,
            transfer_type: 1,
            equipment: main_default_equipment,
            equipment_id: null,
            dataflow: {
                name: ""
            }
        };
    }, [measurement]);

    const submitForm = (formData) => {
        console.log("transfert", formData);
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Segment attached={"top"}>
                            <Grid centered verticalAlign="top">
                                <Grid.Column width={16}>
                                    <Field
                                        name="transfer_type"
                                        placeholder={i18n._(t`select a type of transfer`)}
                                        noResultsMessage={i18n._(t`no result found`)}
                                        options={transferTypeOptions}
                                        isRequired={true}
                                        component={DropDownAdapter}
                                    />
                                </Grid.Column>
                            </Grid>
                            {values.transfer_type === 1 && <EquipmentMainFields form={form} values={values} can_change={true} />}
                            {values.transfer_type === 2 && (
                                <Grid verticalAlign="top">
                                    <Grid.Column width={16}>
                                        <Field
                                            name="equipment_id"
                                            label={i18n._(t`Equipment`)}
                                            placeholder={i18n._(t`Choose an equipment`)}
                                            options={equipmentsOptions}
                                            isRequired={true}
                                            component={DropDownAdapter}
                                            noResultsMessage={i18n._(t`no result found`)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="dataflow.name"
                                            placeholder={i18n._(t`enter name of dataflow`)}
                                            label={i18n._(t`name`)}
                                            parse={identity}
                                            component={InputAdapter}
                                        />
                                    </Grid.Column>
                                </Grid>
                            )}
                        </Segment>
                        <Segment attached basic textAlign="right">
                            <Button type="button" negative icon labelPosition="left" onClick={(e) => setOpen(null)}>
                                <Icon name="arrow left" />
                                <Trans>cancel</Trans>
                            </Button>
                            <Button positive type="submit" disabled={submitting || pristine || invalid}>
                                <Trans>Confirm</Trans>
                            </Button>
                        </Segment>
                    </form>
                );
            }}
        />
    );
};

export default DetachForm;
