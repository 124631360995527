import React, { useEffect, useState } from "react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Grid, Header, Button, Input, Dropdown, Label, Icon, Popup } from "semantic-ui-react";

import {
    resetFilterWithPage,
    setEqtTypeFilter,
    setItemsPerPage,
    setPage,
    setSearchNameFilter,
    setSensorFilter,
    setSiteFilter,
    setStickPage,
    setZoneFilter
} from "modules/equipment/equipmentSlice";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { checkRights } from "modules/auth/utils";

import Back from "modules/common/components/back";
import DeleteEquipment from "./DeleteEquipment";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import history_app from "history_app";
import AddEquipmentModal from "./modal/AddEquipmentModal";

const Equipments = (props) => {
    const { equipments } = props;
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [localSearchName, setLocalSearchName] = useState("");
    const { equipment, sensor, zone, equipmenttype, machine, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const translate = i18n.use(current_lng);

    useEffect(() => {
        (async () => {
            await setLocalSearchName(equipment.filter.searchName);
            await dispatch(setSearchNameFilter(equipment.filter.searchName));
            await dispatch(setSiteFilter(equipment.filter.siteFilter));
            await dispatch(setZoneFilter(equipment.filter.zoneFilter));
            await dispatch(setSensorFilter(equipment.filter.sensorFilter));
            await dispatch(setEqtTypeFilter(equipment.filter.eqtTypeFilter));
            //Change stick page after filter application.
            equipment.pagination.stickPage && (await dispatch(setStickPage(false)));
            setReady(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ready) {
        return <MessageDisplay message={i18n._(t`loading filter`)} level="info" iconName="circle notched" isLoading={true} />;
    }

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: translate._(t`name`), textAlign: "left" },
        { id: "equipmenttype", label: translate._(t`equipmenttype`), textAlign: "left" },
        { id: "site", label: translate._(t`site`), textAlign: "left" },
        { id: "zone", label: translate._(t`zone`), textAlign: "left" },
        { id: "actions", label: translate._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const equipments_list = _.chain(equipments)
        .reduce((res, eqt) => {
            /* name filter */
            if (equipment.filter.searchName === "") {
                res.push(eqt);
            } else if (_.includes(eqt.name.toLowerCase(), equipment.filter.searchName.toLowerCase())) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* sensor filter */
            if (_.size(equipment.filter.sensorFilter) === 0) {
                res.push(eqt);
            } else if (_.chain(equipment.filter.sensorFilter).intersection(eqt.sensor_set).size().value() > 0) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* site filter */
            if (_.size(equipment.filter.siteFilter) === 0) {
                res.push(eqt);
            } else if (_.includes(equipment.filter.siteFilter, eqt.site)) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* zone filter */
            if (_.size(equipment.filter.zoneFilter) === 0) {
                res.push(eqt);
            } else if (_.includes(equipment.filter.zoneFilter, eqt.zone)) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* eqpt_type filter */
            if (_.size(equipment.filter.eqtTypeFilter) === 0) {
                res.push(eqt);
            } else if (_.includes(equipment.filter.eqtTypeFilter, eqt.equipment_type)) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const rights = checkRights(item, auth.rights);
            const can_change = _.includes(rights, "can_change");
            const eqt_type = _.chain(equipmenttype).get("equipmenttypes").find({ id: item.equipment_type }).get("name").value();
            const eqt_site = _.chain(props).get("sites").find({ id: item.site }).get("name").value();
            const eqt_zone = _.chain(zone).get("zones").find({ id: item.zone }).get("name").value();
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };

            const eqt_machine_id = _.get(item, "machine", null);
            const eqt_machine = _.chain(machine).get("machines", []).find({ id: eqt_machine_id }).defaultTo(null).value();

            const display_predict_btn =
                eqt_machine !== null && ((eqt_machine?.is_predict === false && eqt_machine?.machine_type === 7) || eqt_machine?.is_predict === true);

            const last_kinematic_version = _.chain(eqt_machine).get("last_kinematic_version", null).value();

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                equipmenttype: {
                    render: <span style={customStyle}>{i18n._(eqt_type) || "-"}</span>,
                    value: eqt_type || null,
                    textAlign: "left",
                    datatype: "string"
                },
                site: {
                    render: <span style={customStyle}>{eqt_site || "-"}</span>,
                    value: eqt_site || null,
                    textAlign: "right",
                    datatype: "string"
                },
                zone: {
                    render: <span style={customStyle}>{eqt_zone || "-"}</span>,
                    value: eqt_zone || null,
                    textAlign: "right",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Popup
                                    trigger={
                                        <Button
                                            color="facebook"
                                            icon={notification.srv_status.db_status === "rw" && can_change ? "edit" : "eye"}
                                            onClick={async (e) => {
                                                await dispatch(setStickPage(true));
                                                history_app.push(`equipments2/${item.id}`);
                                            }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        {can_change && <Trans>equipment edition</Trans>}
                                        {!can_change && <Trans>View equipment</Trans>}
                                    </Popup.Content>
                                </Popup>
                                <Popup
                                    trigger={
                                        <Button
                                            icon={notification.srv_status.db_status === "rw" && can_change ? "edit" : "eye"}
                                            onClick={async (e) => {
                                                await dispatch(setStickPage(true));
                                                history_app.push(`equipments/${item.id}`);
                                            }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        {can_change && <Trans>equipment edition</Trans>}
                                        {!can_change && <Trans>View equipment</Trans>}
                                    </Popup.Content>
                                </Popup>
                                {display_predict_btn && (
                                    <>
                                        {last_kinematic_version > 0 && (
                                            <>
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            icon="calendar alternate outline"
                                                            onClick={async (e) => {
                                                                await dispatch(setStickPage(true));
                                                                history_app.push(`machines/${item.machine}/events`);
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Popup.Content>
                                                        <Trans>list machine events</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            icon
                                                            onClick={async (e) => {
                                                                await dispatch(setStickPage(true));
                                                                history_app.push(`kinematic/${item.machine}/view`);
                                                            }}
                                                        >
                                                            <Icon.Group>
                                                                <Icon name="gg" />
                                                                <Icon name="eye" corner="top right" />
                                                            </Icon.Group>
                                                        </Button>
                                                    }
                                                >
                                                    <Popup.Content>
                                                        <Trans>kinematic view</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                            </>
                                        )}
                                        {notification.srv_status.db_status === "rw" &&
                                            last_kinematic_version === 0 &&
                                            _.includes(rights, "can_change") && (
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            icon="gg"
                                                            onClick={async (e) => {
                                                                await dispatch(setStickPage(true));
                                                                history_app.push(`kinematic/${item.machine}/change`);
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Popup.Content>
                                                        <Trans>kinematic edition</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                            )}
                                    </>
                                )}
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete") && <DeleteEquipment item={item} />}
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    const zoneOptions = _.chain(zone)
        .get("zones")
        .reduce((res, zone) => {
            if (_.size(equipment.filter.siteFilter) === 0) {
                res.push(zone);
            } else if (_.includes(equipment.filter.siteFilter, zone?.site_id)) {
                res.push(zone);
            }
            return res;
        }, [])
        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="teal">{text}</Label> }))
        .orderBy((item) => {
            return removeAccents(item.text).toLowerCase();
        }, "asc")
        .value();

    const sensorOptions = _.chain(sensor)
        .get("sensors")
        .reduce((res, sensor) => {
            if (_.size(equipment.filter.siteFilter) === 0) {
                res.push(sensor);
            } else if (_.includes(equipment.filter.siteFilter, sensor?.site)) {
                res.push(sensor);
            }
            return res;
        }, [])
        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="green">{text}</Label> }))
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            target="/manage"
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>equipments management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={16} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search name of equipment`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                        dispatch(setSearchNameFilter(value));
                                    }}
                                    value={localSearchName}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.map(equipmenttype.equipmenttypes, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                    placeholder={i18n._(t`select equipment type`)}
                                    multiple
                                    selection
                                    onChange={(e, { value }) => {
                                        dispatch(setEqtTypeFilter(value));
                                    }}
                                    value={equipment.filter.eqtTypeFilter}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.chain(props.sites)
                                        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="blue">{text}</Label> }))
                                        .orderBy((item) => {
                                            return removeAccents(item.text).toLowerCase();
                                        }, "asc")
                                        .value()}
                                    placeholder={i18n._(t`select sites`)}
                                    multiple
                                    selection
                                    search
                                    onChange={(e, { value }) => {
                                        dispatch(setSiteFilter(value));
                                        dispatch(setZoneFilter([]));
                                        dispatch(setSensorFilter([]));
                                    }}
                                    renderLabel={(label) => ({ color: "blue", content: label.text })}
                                    value={equipment.filter.siteFilter}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={3}>
                                {_.size(equipment.filter.siteFilter) === 0 && (
                                    <Popup trigger={<Dropdown fluid options={[]} placeholder={i18n._(t`select zones`)} multiple selection />}>
                                        <Popup.Content>
                                            <Trans>You must choose at least one site to select areas</Trans>
                                        </Popup.Content>
                                    </Popup>
                                )}
                                {_.size(equipment.filter.siteFilter) > 0 && (
                                    <Dropdown
                                        fluid
                                        disabled={_.size(equipment.filter.siteFilter) === 0}
                                        options={zoneOptions}
                                        placeholder={i18n._(t`select zones`)}
                                        multiple
                                        selection
                                        onChange={(e, { value }) => {
                                            dispatch(setZoneFilter(value));
                                        }}
                                        renderLabel={(label) => ({ color: "teal", content: label.text })}
                                        value={equipment.filter.zoneFilter}
                                    />
                                )}
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={3}>
                                <Dropdown
                                    className="label_sensor_equipment"
                                    fluid
                                    options={sensorOptions}
                                    placeholder={i18n._(t`select sensors`)}
                                    multiple
                                    selection
                                    search
                                    // disabled={_.size(equipment.filter.siteFilter) === 0}
                                    noResultsMessage={i18n._(t`no sensor found`)}
                                    onChange={(e, { value }) => {
                                        dispatch(setSensorFilter(value));
                                    }}
                                    renderLabel={(label) => {
                                        return { color: "green", content: label.text };
                                    }}
                                    value={equipment.filter.sensorFilter}
                                />
                            </Grid.Column>
                            {notification.srv_status.db_status === "rw" && _.size(auth.rights?.sites_rw) > 0 && (
                                <Grid.Column width={16}>
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            await dispatch(resetFilterWithPage());
                                            history_app.push(`equipments/add`);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>add equipment and calculated measure</Trans>
                                    </Button>
                                    <Button
                                        color="facebook"
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            setOpenAdd(true);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>add equipment and calculated measure</Trans>
                                    </Button>
                                </Grid.Column>
                            )}
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={equipments_list}
                                    textItemPerPages={translate._(t`items per page`)}
                                    page={equipment.pagination.page}
                                    rowsPerPage={equipment.pagination.itemsPerPage}
                                    setPage={(page) => {
                                        dispatch(setPage(page));
                                    }}
                                    setItemsPerPage={(items) => {
                                        dispatch(setItemsPerPage(items));
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {openAdd && <AddEquipmentModal setOpen={setOpenAdd} />}
        </Segment>
    );
};

export default Equipments;
