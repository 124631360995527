import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { Trans } from "@lingui/macro";
import { Button, Divider, Icon, Segment } from "semantic-ui-react";

import MeasurementFields from "modules/equipment/components/form/MeasurementFields";

const MeasurementForm = (props) => {
    const { setOpen } = props;
    const initialValues = useMemo(() => {
        return {
            dataflow: {
                name: props?.measurement?.name ?? "",
                dataflowspec: props?.measurement?.dataflowspec ?? null,
                standby_threshold: props?.measurement?.standby_threshold ?? null
            },
            syndataflow: {
                formula: "",
                syndataflowtype: 1,
                formula_type: 1,
                ...props?.measurement?.syndataflow
            },
            measurement: {
                display_unit: props?.measurement?.display_unit || -1,
                measurementtype: props?.measurement?.measurementtype ?? null,
                factor: props?.measurement?.factor ?? null,
                offset: props?.measurement?.offset ?? null,
                minGauge: props?.measurement?.minGauge ?? null,
                maxGauge: props?.measurement?.maxGauge ?? null
            }
        };
    }, [props.measurement]);

    const isSynthetic = props?.measurement === undefined || (props?.measurement?.syndataflow ?? null) !== null;
    const isPulse = (props?.measurement?.dataflowspec_tech ?? null) === 8; //pk:8 => pulse

    const submitForm = (formData) => {
        console.log("submit", formData);
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <Segment attached>
                        <form onSubmit={handleSubmit} className="ui form">
                            <MeasurementFields form={form} values={values} isSynthetic={isSynthetic} isPulse={isPulse} />
                            <Segment basic textAlign="right">
                                <Divider />
                                {setOpen && (
                                    <Button type="button" negative icon labelPosition="left" onClick={(e) => setOpen(false)}>
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                )}
                                <Button positive type="submit" disabled={submitting || pristine || invalid}>
                                    <Trans>Confirm</Trans>
                                </Button>
                            </Segment>
                        </form>
                    </Segment>
                );
            }}
        />
    );
};

export default React.memo(MeasurementForm);
