import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Button, Divider, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { main_default_equipment } from "modules/equipment/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useCreateEquipment2Mutation } from "modules/equipment/equipmentService";

import EquipmentMainFields from "./EquipmentMainFields";
import MeasurementFields from "./MeasurementFields";

const EquipmentAddForm = (props) => {
    const { setOpen } = props; //needed to close Modal or history.push to new equipment after creation?
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const [createEquipment, create] = useCreateEquipment2Mutation();

    useEffect(() => {
        if (create.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create equipment`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create equipment`);
            toast.error(error, toast_options_err);
        }
        //eslint-disable-next-line
    }, [create.isSuccess, create.isError, create.isLoading]);

    const initialValues = useMemo(() => {
        return {
            equipment: main_default_equipment,
            dataflow: {
                name: "",
                dataflowspec: null,
                standby_threshold: null
            },
            syndataflow: {
                formula: "",
                syndataflowtype: 1,
                formula_type: 1
            },
            measurement: {
                display_unit: -1,
                measurementtype: null,
                factor: null,
                offset: null,
                minGauge: null,
                maxGauge: null
            }
        };
    }, []);

    const can_change = useMemo(() => {
        return _.size(auth.rights?.sites_rw) > 0;
    }, [auth.rights]);

    const submitForm = (formData) => {
        console.log("form", formData);
        if (can_change && notification.srv_status.db_status === "rw") {
            createEquipment({ org: org.current, data: formData });
        }
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Header as="h3" attached="top" block textAlign="center">
                            <Trans>Equipment definition</Trans>
                        </Header>
                        <Segment attached>
                            <EquipmentMainFields form={form} values={values} can_change={can_change} />
                        </Segment>
                        <Header as="h3" attached block textAlign="center">
                            <Trans>Measurement definition</Trans>
                        </Header>
                        <Segment attached>
                            <MeasurementFields form={form} values={values} isSynthetic={true} isPulse={false} />
                        </Segment>
                        {can_change && notification.srv_status.db_status === "rw" && (
                            <>
                                <Divider />
                                <Segment basic>
                                    <Button type="submit" positive content={i18n._(t`validate`)} disabled={submitting || pristine || invalid} />
                                </Segment>
                            </>
                        )}
                    </form>
                );
            }}
        />
    );
};

export default React.memo(EquipmentAddForm);
