import React from "react";
import { Modal } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import MeasurementForm from "../form/MeasurementForm";

const AddMeasurementModal = (props) => {
    const { setOpen, equipment } = props;
    return (
        <Modal open centered={false} onClose={(e) => setOpen(false)} size="fullscreen">
            <Modal.Header>
                <Trans>add calculated measure</Trans>
            </Modal.Header>
            <Modal.Content>
                <MeasurementForm setOpen={setOpen} equipment={equipment} />
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(AddMeasurementModal);
